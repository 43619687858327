export function SnackbarShow(type) {
    var snackbar;
    switch (type) {
        case "term1":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.termsAndConditionsValidation",
                visible: true
            };
            break;
        case "term2":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.useofPersonalInfoValidation",
                visible: true
            };
            break;
        case "term3":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.processPersonalInfoValidation",
                visible: true
            };
            break;
        case "commonTerm":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.commonTermValidation",
                visible: true
            };
            break;
        case "name":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.nameValidation",
                visible: true
            };
            break;
        case "id":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.pleaseEnterID",
                visible: true
            };
            break;
        case "email":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.emailValidation",
                visible: true
            };
            break;
        case "password":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.passwordValidation",
                visible: true
            };
            break;
        case "passwordvalidationMessage":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.passwordvalidationMessage",
                visible: true
            };
            break;
        case "confirmPassword":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.confirmPasswordValidation",
                visible: true
            };
            break;
        case "year":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.yearValidation",
                visible: true
            };
            break;
        case "month":
            this.snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.monthValidation",
                visible: true
            };
            break;
        case "day":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.dayValidation",
                visible: true
            };
            break;
        case "licenseType":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.licensetypeValidation",
                visible: true
            };
            break;
        case "licenseNumber":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.licensenumberValidation",
                visible: true
            };
            break;
        case "emailAlreadyExists":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.emailAlreadyExists",
                visible: true
            };
            break;
        case "licenseNumberAlreadyExists":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "register.licenseNumberAlreadyExists",
                visible: true
            };
            break;
        case "Checking medical personnel":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "memberLogin.checkingMedicalPersonnel",
                visible: true,
            };
            break;
        case "License number mismatch":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "memberLogin.licenseNumberMismatch",
                visible: true
            };
            break;
        case "Birth date mismatch":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "memberLogin.birthDateMismatch",
                visible: true
            };
            break;
        case "Name discrepancy":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "memberLogin.nameDiscrepancy",
                visible: true
            };
            break;

        case "Invalid ID.":
            snackbar = {
                color: "#FF5252",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 2000,
                title: "Warning",
                text: "memberLogin.invalidIdPassword",
                visible: true,
            };
            break;
    }
    return snackbar;
}